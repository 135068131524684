import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import eventBus from "../eventBus";
import SeoHelmet from '../components/seoHelmet';

class ZasadySpracuvaniaOsobnychUdajov extends Component {
  onClickChild(){
    eventBus.dispatch("showCookieBar");
  }
  render() {
    const {data} = this.props;
    return (
      <div className={'container privacy-policy'}>
        <SeoHelmet
          title={'GDPR - Narative.sk'}
        />
        <h1>Zásady spracúvania osobných údajov</h1>
        <p onClick={this.onClickChild} className="link-primary text-center cookie-setter mt-5 pb-0 mb-0">Zmena nastavení cookies tu</p>
        <h2>1. Prevádzkovateľ</h2>
        <p>Prevádzkovateľ je osoba, ktorá spracúva osobné údaje dotknutej osoby (Vás). Prevádzkovateľom webovej adresy www.narative.sk je spoločnosť Naradesign s. r. o. so sídlom Jakabova 42, Bratislava 821 04, IČO: 46 082 824, zapísaná v Obchodnom registri Okresného súdu Bratislava I, odd. Sro, vl. č. 71476/B (ďalej ako „Naradesign“).</p>
        <br/>
        <p>V prípade, ak máte akékoľvek otázky, môžete sa na nás obrátiť (i) prostredníctvom elektronickej pošty na adrese info@narative.sk alebo (ii) klasickou poštou na našu adresu Naradesign s. r. o., Jakabova 42, Bratislava 821 04.</p>

        <h2>2. Dotknutá osoba</h2>
        <p>Dotknutou osobou ste Vy, pretože Vaše osobné údaje Naradesign spracúva.</p>

        <h2>3. Osobné údaje</h2>
        <p>Osobnými údajmi sú akékoľvek údaje, na základe ktorých je dotknutá osoba identifikovaná alebo identifikovateľná. Rozsah Vašich osobných údajov, ktoré Naradesign spracúva, závisí od účelu, na ktorý sú tieto osobné údaje spracúvané.</p>
        <p>Jednotlivé kategórie a typy osobných údajov, ktoré spracúvame, vždy závisia od účelu pre ktorý Vaše osobné údaje spracúvame a právneho základu, na základe ktorého ich spracúvame. K osobným údajom, ktoré môžeme od Vás požadovať, patria najmä:</p>
        <p>(a) identifikačné údaje (meno a priezvisko, tituly pred menom a za menom, dátum narodenia, fotografie, online identifikátory, IP adresy,…),<br/>
          (b) kontaktné údaje (adresy pobytu, bydlisko, fakturačná adresa, doručovacia adresa, adresa elektronickej pošty, telefónne číslo),<br/>
          (c) údaje o ekonomickej identite (čísla bankových účtov, čísla daňových, účtovných, dodacích a iných dokladov, údaje o platbách),<br/>
          (e) spotrebiteľské údaje (IP adresy, údaje o nákupoch a nákupných preferenciách, údaje z cookies),<br/>
          (f) lokalizačné údaje</p>
        <p>Nespracúvame osobitné kategórie osobných údajov, ktoré sú obzvlášť citlivé (údaje odhaľujúce rasový alebo etnický pôvod (nie je možné teda spracovávať národnosť, údaje odhaľujúce politické názory, údaje odhaľujúce náboženskú vieru alebo filozofické presvedčenie, členstvo v odboroch / len na účely plnenia zákonných povinností podľa Zákonníka práce, genetické údaje, biometrické údaje a údaje týkajúce sa sexuálneho života alebo sexuálnej orientácie).</p>

        <h2>4. Zdroje osobných údajov</h2>
        <p>Naradesign získava Vaše osobné údaje obvykle priamo od Vás. Osobné údaje (i) sú uvedené predovšetkým na zmluvách, ktoré sme s Vami uzavreli, alebo (ii) ste ich vyplnili vo formulároch na webovej stránke, prípadne (iii) ste nám ich zaslali alebo poskytli v rámci vzájomnej korešpondencie alebo komunikácie, alebo (iv) sme ich odvodili od iných údajov, ktoré ste nám poskytli.</p>

        <h2>5. Účel spracúvania osobných údajov</h2>
        <p>Naradesign spracúva Vaše osobné údaje na účely, ktoré jej vyplývajú z právneho predpisu, zmluvy alebo zo súhlasu, ktorý ste jej udelili. Každý účel spracúvania osobných údajov je vždy presne konkretizovaný v príslušnom dokumente, ktorý ho upravuje.</p>

        <h2>6. Právne základy spracúvania osobných údajov</h2>
        <p>Vaše osobné údaje spracúvame na základe niektorého z nasledujúcich právnych základov:</p>

        <p>
          (a) <b>Súhlas dotknutej osoby</b> so spracúvaním osobných údajov na jeden alebo viacero konkrétnych účelov.<br/>
          (b) <b>Plnenie zmluvy</b>, ktorej zmluvnou stranou je dotknutá osoba, alebo vykonanie opatrenia pred uzatvorením zmluvy na základe žiadosti dotknutej osoby.<br/>
          (c) <b>Splnenie zákonnej povinnosti</b> podľa osobitného predpisu alebo medzinárodnej zmluvy, ktorou je Slovenská republika viazaná.<br/>
          (d) <b>Ochrana životne dôležitých záujmov</b> dotknutej osoby alebo inej fyzickej osoby (života, zdravia alebo majetku dotknutej osoby alebo inej fyzickej osoby).
        </p>

        <h2>7. Odvolanie súhlasu</h2>
        <p>Ak je právnym základom spracúvania Vašich osobných údajov súhlas, môžete ho kedykoľvek odvolať (i) elektronickou poštou na adresu info@narative.sk, (ii) písomne na adresu Naradesign s. r. o., Jakabova 42, Bratislava 821 04, (iii) osobne, (iv) prostredníctvom odkazu v správe alebo (v) v rozhraní online služby, ktorú využívate a ktorá túto funkcionalitu umožňuje. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov založeného na súhlase pred jeho odvolaním. V prípade
          odvolania súhlasu Vaše osobné údaje prestaneme bezodkladne spracúvať a vymažeme ich.</p>
        <p>V prípade, ak Naradesign spracúva Vaše osobné údaje aj na inom právnom základe, ako je Váš súhlas, odvolaním súhlasu nezaniká jej oprávnenie spracúvať Vaše osobné údaje na iných právnych základoch.</p>

        <h2>8. Právo namietať a právo podať návrh na začatie konania o ochrane osobných údajov</h2>
        <p>Máte právo podať námietky voči spracúvaniu osobných údajov priamo Naradesign (bod 13, písmeno (a) a bod 14 týchto Pravidiel) (i) elektronickou poštou na adrese info@narative.sk, alebo (ii) klasickou poštou na našu adresu Naradesign s. r. o., Jakabova 42, Bratislava 821 04.</p>
        <p>Okrem práva podať námietky máte aj právo podať návrh na začatie konania o ochrane osobných údajov pred <a href={'https://dataprotection.gov.sk/uoou/'}>Úradom na ochranu osobných údajov Slovenskej republiky</a>.</p>

        <h2>9. Príjemcovia osobných údajov</h2>
        <p>Naradesign spracúva Vaše osobné údaje predovšetkým na vlastné účely. Okrem toho môže poskytnúť Vaše osobné údaje našim obchodným partnerom, prostredníctvom ktorých Vaše osobné údaje spracúvame. Ide o dôveryhodné osoby (tzv. príjemcovia a sprostredkovatelia), ktoré pre nás zabezpečujú vývoj, údržbu a prevádzkovanie softvérových riešení a iných služieb, prostredníctvom ktorých Vaše osobné údaje spracúvame, alebo nám poskytujú služby (napr. ekonomické, právne, kuriérske, audítorské a
          marketingové).</p>
        <p>Príjemcami Vašich osobných údajov sú okrem toho naši zamestnanci alebo externí kontraktori. Vaše osobné údaje môžeme s Vaším súhlasom poskytnúť aj tretím osobám, ktoré budú Vaše osobné údaje spracúvať na ich marketingové potreby. Vaše osobné údaje nebudú zverejnené.</p>

        <h2>10. Prenos osobných údajov do tretích krajín</h2>
        <p>Vaše osobné údaje spracúvame predovšetkým v rámci Európskej únie a Európskeho hospodárskeho priestoru.</p>
        <p>V prípadoch cezhraničných prenosoch je spracúvanie Vašich osobných údajov zabezpečené certifikačným systémom Privacy Shield, ktorý bol vytvorený americkým Ministerstvom obchodu, Európskou komisiou a Švajčiarskou vládou, aby mali subjekty z Európskeho hospodárskeho priestoru a Spojených štátov amerických prostriedky na splnenie požiadaviek na ochranu osobných údajov pri ich prenose z členského štátu Európskeho hospodárskeho priestoru do Spojených štátov amerických, a na účely podpory
          transatlantického obchodu. Tento systém podľa rozhodnutia Európskej komisie zabezpečuje primeranú ochranu osobných údajov. Bližšie informácie o tomto systéme sa môžete dozvedieť na jeho <a href={'https://www.privacyshield.gov/'}>webovej stránke</a>.</p>

        <h2>11. Doba spracúvania osobných údajov</h2>
        <p>Doba spracúvania Vašich osobných údajov závisí od právneho základu ich spracúvania. Môže byť určená priamo právnym predpisom alebo bude uvedená v zmluve, ktorú sme s Vami uzavreli, alebo v súhlase, ktorý ste nám poskytli.</p>
        <p>V prípade, ak nie je doba spracúvania uvedená priamo v právnom predpise alebo zmluve, štandardne budeme spracúvať osobné údaje (i) po dobu 24 mesiacov od začiatku ich spracúvania alebo (ii) po dobu plynutia premlčacej doby, ak je právnym základom náš oprávnený záujem (napr. v prípade uplatnenia právneho nároku).</p>
        <p>Po uplynutí tejto doby, zániku dôvodu spracúvania osobných údajov alebo pri odvolaní Vášho súhlasu Vaše osobné údaje vymažeme (okrem prípadu, ak Vaše osobné údaje spracúvame na inom právnom základe, ako je súhlas). Pred uplynutím tejto doby Vás môžeme požiadať o predĺženie platnosti Vášho súhlasu na ďalších 24 mesiacov.</p>

        <h2>12. Spôsob spracúvania osobných údajov</h2>
        <p>Naradesign spracúva Vaše osobné údaje predovšetkým elektronicky prostredníctvom automatizovaných informačných systémov, ktoré prevádzkuje sama alebo prostredníctvom svojich obchodných partnerov (napr. Microsoft Corporation, Google a pod.). Vaše osobné údaje môžeme spracúvať aj v ich listinnej podobe (napr. zmluvy, účtovné a daňové doklady, listinná korešpondencia) v systematicky usporiadaných evidenciách.</p>

        <h2>13. Práva dotknutej osoby</h2>

        <p><b>(a) PRÁVO NAMIETAŤ:</b> Proti spracúvaniu Vašich osobných údajov máte právo namietať. Namietať môžete vtedy, ak sú Vaše osobné údaje spracúvané z dôvodu plnenia úlohy vo verejnom záujme alebo oprávneného záujmu Naradesign alebo na účely priameho marketingu. Vaša námietka bude oprávnená a úspešná v prípade, ak Vaše oprávnené dôvody prevažujú nad oprávnenými dôvodmi Naradesign alebo ak ide o spracúvanie Vašich osobných údajov na účely priameho marketingu (v takom prípade je námietka
          vyhodnotená ako odvolanie Vášho súhlasu). Namietať nemôžete v prípade, ak je spracúvanie nevyhnutné na (i) splnenie úlohy z dôvodov verejného záujmu alebo (ii) vedecké alebo štatistické účely alebo účely historického výskumu. Ak je Vaša námietka oprávnená a úspešná, Vaše osobné údaje nebudeme ďalej spracúvať.</p>
        <p><b>(b) PRÁVO NA INFORMÁCIE:</b> Máte právo na poskytnutie informácií o tom, či Vaše osobné údaje spracúvame, v akom rozsahu ich spracúvame, a na informácie o porušení ochrany Vašich osobných údajov.</p>
        <p><b>(c) PRÁVO NA PRÍSTUP K OSOBNÝM ÚDAJOM:</b> Máte právo na vystavenie potvrdenia o tom, že Vaše osobné údaje spracúvame, aké Vaše osobné údaje spracúvame a na kópiu týchto osobných údajov. Toto potvrdenie Vám poskytneme elektronickou poštou, ak nás nepožiadate o inú formu. Môžeme odmietnuť poskytnúť Vám toto potvrdenie v prípade, ak by to malo nepriaznivé dôsledky na práva iných fyzických osôb.</p>
        <p><b>(d) PRÁVO NA OPRAVU:</b> V prípade, ak sú Vaše osobné údaje, ktoré spracúvame, nesprávne alebo neúplné, máte právo požadovať ich opravu alebo doplnenie.</p>
        <p><b>(e) PRÁVO NA VÝMAZ/PRÁVO NA ZABUDNUTIE:</b> Po ukončení spracúvania osobných údajov (odvolanie súhlasu, úspešná námietka, uplynutie doby a pod.) alebo pri ich spracúvaní v rozpore s právnymi predpismi máte právo na ich vymazanie a Naradesign Vaše osobné údaje v takomto prípade bezodkladne vymaže. O vymazanie osobných údajov môžete tiež požiadať. Výmaz je vždy konečný. Právo na výmaz nie je možné uplatniť, ak sú osobné údaje potrebné napr. na uplatnenie práva na slobodu prejavu a
          práva na informácie, splnenie povinnosti podľa právneho predpisu, splnenie úlohy realizovanej vo verejnom záujme, z dôvodov verejného záujmu v oblasti verejného zdravia, na archivačné, vedecké alebo štatistické účely alebo účel historického výskumu alebo na uplatnenie právnych nárokov.</p>
        <p><b>(f) PRÁVO NA OBMEDZENIE SPRACÚVANIA:</b> V prípade, ak namietate správnosť Vašich osobných údajov, Naradesign obmedzí ich spracúvanie na dobu overenia ich správnosti. Ak sú Vaše osobné údaje spracúvané nezákonne a nepožadujete ich vymazanie, obmedzíme ich spracúvanie v rozsahu, v akom to budete požadovať, inak iba na ich archivovanie. Ak sú Vaše osobné údaje spracúvané z dôvodu plnenia úlohy verejného záujmu alebo na základe oprávneného záujmu Naradesign, bude ich spracúvanie
          obmedzené na dobu preverenia, či oprávnené dôvody spracúvania prevažujú nad oprávnenými dôvodmi dotknutej osoby. O obmedzenie spracúvania Vašich osobných údajov nás môžete tiež požiadať. O začiatku a ukončení obmedzenia spracúvania Vašich osobných údajov Vás budeme informovať elektronickou poštou.</p>
        <p><b>(g) PRÁVO NA PRENOSNOSŤ:</b> V prípade, ak (i) je to technicky možné, (ii) spracúvanie Vašich osobných údajov sa deje na základe Vášho súhlasu alebo z dôvodu plnenia zmluvy, (iii) a nie z dôvodu splnenia úlohy vo verejnom záujme, (iv)spracúvanie sa vykonáva automatizovanými prostriedkami, a(v) prenos osobných údajov nemá nepriaznivé dôsledky na práva iných osôb, máte právo na to, aby Vaše osobné údaje boli spracúvané v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte
          (napr. .xml). Pri takto spracúvaných osobných údajov máte právo žiadať o ich prenos k inému prevádzkovateľovi.</p>
        <p><b>(h) PRÁVO ODMIETNUŤ AUTOMATIZOVANÉ INDIVIDUÁLNE ROZHODOVANIE A PROFILOVANIE:</b> Máte právo odmietnuť rozhodnutie, ktoré je založené na automatizovanom spracúvaní osobných údajov a profilovaní, ak má právne účinky alebo dotknutú osobu významne ovplyvňujú. Právo nie je možné uplatniť, ak je takéto rozhodnutie potrebné na uzavretie alebo plnenie zmluvy alebo je vykonané na základe právneho predpisu, alebo na základe súhlasu dotknutej osoby. Každé rozhodnutie založené automatizovanom
          spracúvaní osobných údajov a profilovaní musí byť preskúmateľné manuálne. Dotknutá osoba je oprávnená rozhodnutie písomne napadnúť alebo poskytnúť k nemu svoje stanovisko.</p>

        <h2>14. Spôsob uplatnenia práv a poučenia</h2>
        <p>Svoje práva a požiadavky môžete uplatniť elektronickou poštou na adresu info@narative.sk, písomne na adresu Naradesign s. r. o., Jakabova 42, Bratislava 821 04, osobne alebo iným vyššie uvedeným spôsobom. V prípade, ak svoju žiadosť, námietku, stanovisko alebo odvolanie súhlasu doručíte inak ako listom s Vaším podpisom, elektronickou poštou s kvalifikovaným elektronickým podpisom alebo osobne, môžeme od Vás požadovať dodatočné dôveryhodné overenie Vašej totožnosti, inak nebudeme
          musieť Vašej požiadavke vyhovieť. Vašej žiadosti nemusíme vyhovieť ani v prípadoch, ktoré upravujú právne predpisy.</p>
        <p>Vaše požiadavky budú vybavené najneskôr do jedného mesiaca od ich doručenia. Túto lehotu môžeme predĺžiť o ďalšie dva mesiace, a to aj opakovane, ak ide o odôvodnený prípad, a s ohľadom na komplexnosť a počet požiadaviek. O predĺžení lehoty Vás budeme informovať.</p>
        <p>Informácie, potvrdenia a oznámenia Vám budeme poskytovať bezodplatne. V prípade opakovaných žiadostí, alebo žiadostí, ktoré sú zjavne neopodstatnené alebo neprimerané, môžeme od Vás požadovať zaplatenie administratívnych nákladov vynaložených na ich vybavenie, alebo môžeme ich vybavenie odmietnuť.</p>

        <h2>15. Zásady spracúvania osobných údajov</h2>
        <p><b>(a) ZÁSADA ZÁKONNOSTI:</b> Osobné údaje spracúvame spravodlivo, transparentne a len na základe zákonného dôvodu (súhlas dotknutej osoby, plnenie zmluvy, plnenie zákonnej povinnosti, ochrana životne dôležitých záujmov dotknutej alebo inej fyzickej osoby, plnenie úlohy vo verejnom záujme alebo pri výkone verejnej moci, oprávnený záujem Naradesign). Pri spracúvaní nebudeme porušovať práva dotknutej osoby.</p>
        <p><b>(b) ZÁSADA OBMEDZENIA ÚČELU:</b> Osobné údaje môžeme spracúvať len na konkrétny, presne určený, výslovne uvedený a oprávnený účel, inak len na archivačné, vedecké alebo štatistické účely alebo účely historického výskumu.</p>
        <p><b>(c) ZÁSADA MINIMALIZÁCIE OSOBNÝCH ÚDAJOV:</b> Môžeme spracúvať len tie osobné údaje, ktoré nevyhnutne potrebujeme, alebo tie, ktoré nám umožňuje spracúvať zákon. Nepotrebné osobné údaje nebudeme spracúvať ani ich vyžadovať. Je zároveň aj na Vás, aby ste nám poskytli len tie osobné údaje, ktoré sú nevyhnutné potrebné na účel, na ktorý ich budeme spracúvať a neposkytovali nám nadbytočné údaje (napr. pri vzájomnej komunikácii a pod.).</p>
        <p><b>(d) ZÁSADA SPRÁVNOSTI:</b> Spracúvame len správne a aktuálne osobné údaje. Ak sú nami spracúvané osobné údaje nesprávne alebo neúplné, tak ich opravíme alebo doplníme. Nesprávne osobné údaje vymažeme. Správnosť osobných údajov taktiež závisí aj od Vás, a preto je nevyhnutné, aby ste nám vždy poskytli správne a úplné osobné údaje.</p>
        <p><b>(e) ZÁSADA MINIMALIZÁCIE UCHOVÁVANIA:</b> Osobné údaje budeme spracúvať len po dobu potrebnú na splnenie účelu alebo stanovenú právnym predpisom. Dlhšie ich budeme spracúvať len na archivačné, vedecké alebo štatistické účely alebo účely historického výskumu.</p>
        <p><b>(f) ZÁSADA INTEGRITY A DÔVERNOSTI:</b> Osobné údaje ochránime pred stratou, náhodným vymazaním, poškodením, odcudzením alebo zničením. Zabezpečíme, aby nedochádzalo k nezákonnému spracúvaniu osobných údajov. Bezpečnosť osobných údajov je dôležitá a zabezpečujeme ju prostredníctvom štandardných primeraných technických a iných opatrení.</p>
        <p><b>(g) ZÁSADA ZODPOVEDNOSTI:</b> Zodpovedáme za bezpečnosť a ochranu osobných údajov Vám a Úradu na ochranu osobných údajov Slovenskej republiky, ktorý je oprávnený preskúmavať spracúvanie osobných údajov v rámci konania o ochrane osobných údajov.</p>

        <h2>16. Spracúvanie osobných údajov detí</h2>
        <p>Osobné údaje detí mladších ako 16 rokov je možné spracúvať len v osobitných a odôvodnených prípadoch. V prípade, ak máte menej ako 16 rokov, a poskytli ste nám súhlas so spracúvaním Vašich osobných údajov, je potrebné, aby bol tento súhlas udelený a potvrdený aj Vaším zákonným zástupcom. V tejto súvislosti môžeme od Vás požadovať dátum narodenia ako jeden z osobných údajov na účely overenia Vášho veku. Ak Vaše osobné údaje spracúvame na inom právnom základe ako je Váš súhlas, môžeme vyžadovať potvrdenie a súhlas Vášho zákonného zástupcu.</p>

        <h2>17. Výluka z pravidiel</h2>
        <p>Stránka <Link to={'/'}>www.narative.sk</Link> je chránená službou reCAPTCHA a uplatňujú sa na nej pravidlá ochrany osobných údajov a zmluvné podmienky spoločnosti Google. Je to uvedené aj na stránke, uvedené pravidlá sa považujú za nadradené a prioritné pravidlám uvedeným v tomto dokumente pre oblasti ochrany osobných údajov, ktoré chránia.</p>

        <h2>18. Odkazy na iné webové stránky</h2>
        <p>Táto Stránka môže obsahovať odkazy na ďalšie webové stránky, napr. webové stránky našich partnerov, spolupracujúcich spoločností alebo webové stránky sociálnych médií. Po kliknutí na odkaz, prosím majte na zreteli, že odkazované stránky majú svoje vlastné zásady ochrany osobných údajov. Skontrolujte si prosím zásady ochrany súkromia pri používaní týchto webových stránok. Dovoľujeme si upozorniť, že nenesieme akúkoľvek zodpovednosť za webové stránky tretích strán.</p>

        <h2>19. Čo sú cookies a aké druhy používame?</h2>
        <p>Pre optimalizáciu stránky <Link to={'/'}>www.narative.sk</Link> z hľadiska použiteľnosti, systémového výkonu, poskytovania užitočných informácií o našich projektoch a doplnkových službách, sú automaticky zhromažďované a uchovávané informácie v log súboroch vo Vašom počítači. To zahŕňa typ prehliadača, adresu internetového protokolu (IP), jazykové nastavenia, operačný systém, služieb internetu (ISP), dátum/čas a i. Tieto informácie sú používané pre efektívne spravovanie a nastavenia. Zhromaždené informácie môžu byť použité pre marketingové, reklamné služby, optimalizáciu prevádzky stránky (napríklad zlepšenie užívateľského prostredia, poskytujúce nové atraktívnejšie služby). Táto Stránka, e-mailové správy, online služby, reklamy a interaktívne aplikácie môžu používať „cookies“ pre optimalizáciu našich služieb.</p>

        <h2>20. Čo je „cookie“?</h2>
        <p>„Cookie“ je malý súbor, väčšinou pozostávajúci z písmen a číslic, zasielaný do cookie súboru prehliadača na pevnom disku Vášho počítača prostredníctvom web servera. Toto napríklad umožňuje internetovej stránke rozpoznať užívateľovo zariadenie, v momente keď došlo k spojeniu medzi web serverom a internetovým prehliadačom. Hlavným účelom cookies je umožniť web serveru poskytnúť užívateľovi prispôsobené webové stránky, ktoré robia skúsenosť pri návšteve webovej našich stránok viac osobnou a lepšie zodpovedajúcou užívateľovym individuálnym potrebám.</p>

        <h2>21. Môžeme využívať dva druhy cookies na stránke www.narative.sk:</h2>
        <p>*Trvalé cookies: pre lepšiu užívateľskú skúsenosť (napr. zabezpečenie optimálnej navigácie) môžeme využívať trvalé cookies. Tieto cookies zostávajú v cookie súbore Vášho prehliadača po dlhšiu dobu. Táto doba bude závisieť od voľby, ktorú si vyberiete v nastaveniach Vášho internetového prehliadača. Trvalé cookies umožňujú posielanie informácií na web server zakaždým, keď niekto navštívi stránku.</p>
        <p>*Relačné cookies: sú dočasné cookies, ktoré zostávajú uložené v cookie súbore Vášho prehliadača do momentu, kým ukončíte prehliadanie. Tieto cookies sú potrebné pre určité aplikácie a funkcionality na tejto Stránke, aby fungovala správne.</p>

        <h2>22. Povolenie, zakázanie cookies</h2>
        <p>Máte možnosť povoliť alebo zakázať všetky cookies na všetkých webových stránkach, ktoré navštívite, prostredníctvom nastavení Vášho internetového prehliadača.</p>
        <p>Napríklad ak používate Internet Explorer verzia 8.0, musíte vykonať nasledovné: 1. zvoľte Nástroje a následne Možnosti siete Internet 2. kliknite na kartu Ochrana osobných údajov 3. použite posuvný bežec pre voľbu nastavenia, aké Vám vyhovuje. Pri iných internetových prehliadačoch si prosím prečítajte pokyny prehliadača, aby ste sa dozvedeli viac ako upraviť nastavenia prehliadača a ako vymazať cookies. Naša Stránka Vás oboznamuje s používaním cookies a vyzýva na ich povolenie. Cookies môžete teda vypnúť. Majte však prosím na zreteli, že niektoré funkcie a plná funkcionalita tejto Stránky nebudú dostupné, keď budú cookies zakázané.</p>

        <h2>23. Analýza dát a ich sledovanie</h2>
        <p>Pre neustálu optimalizáciu správy publikovaného obsahu stránky <Link to={'/'}>www.narative.sk</Link> a svojej externej komunikácie môžeme využívať analytický software. To umožňuje sledovanie on-line správania, pokiaľ ide o čas, geografickú polohu, využitie stránky <Link to={'/'}>www.narative.sk</Link> a i. Tieto informácie sa zhromažďujú, sú anonymné a nebudú spojené s osobnými údajmi. Povolenie cookies na Vašom počítači umožňuje publikovať obsah, ktorý bude pre Vás užitočnejší. Tiež je možné merať prevádzku stránky <Link to={'/'}>www.narative.sk</Link> pre zabezpečenie jej kvalitného chodu. Neposkytujeme tento druh informácií tretím stranám pre iné použitie.</p>

        <h2>24. Iné informácie a vzťah k iným pravidlám a podmienkam Naradesign</h2>
        <p>Tieto pravidlá ochrany osobných údajov Naradesign s.r.o. sú všeobecnými pravidlami a poskytujú všeobecný a základný rámec pre ochranu Vašich osobných údajov. Naradesign môže na rozličné účely spracúvania osobných údajov prijať osobitné pravidlá (napr. na spracúvanie osobných údajov pri marketingových aktivitách) a rovnako budú tieto pravidlá ochrany osobných údajov súčasťou jednotlivých obchodných a iných podmienok Naradesign s.r.o. na poskytovanie a používanie jej jednotlivých služieb a produktov. Osobitné pravidlá ochrany osobných údajov majú prednosť pred týmito všeobecnými pravidlami.</p>

        <p>Tieto pravidlá boli vytvorené na účely vysvetlenia ochrany osobných údajov a posilnenia transparentnosti pri ich spracúvaní. Tieto pravidlá budú pravidelne aktualizované a zverejnené na webovej stránke www.narative.sk. Pravidlá nadobúdajú účinnosť 1.12.2019.</p>

        <div className="row">
          <div className="col-6">
            <b>V Bratislave, dňa 1.12.2019,</b>
          </div>
          <div className="col-6 d-flex flex-column align-items-end">
            <b>Mgr. Michal Vasil, konateľ Naradesign s.r.o.</b>
            <Img fixed={data.signature.childImageSharp.fixed} alt="gdpr"/>
          </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }
}

export const query = graphql`{
    signature: file(relativePath: { eq: "signature.JPG" }) {
        childImageSharp {
            fixed( width: 300) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
}
`

export default ZasadySpracuvaniaOsobnychUdajov